import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { usePhoto } from './Photo-ViewModel'
import { IconButton, Pagination, Typography } from '@mui/material'
import { Controls } from 'assets/images/Controls'
import { ImageLoad } from 'ui/shared/ImageLoad/ImageLoad'
export const Photo: FC = () => {
  const vm = usePhoto()
  const { closeModal, choosen, handlePageChange, rent } = vm

  return (
    <Shared.Card style={{ width: 800, overflowY: 'auto', maxHeight: '100%' }}>
      <Grid container spacing={3}>
        <Grid container direction={'row'} justifyContent={'space-between'} style={{ margin: 16, marginBottom: 8 }}>
          <Shared.CardTitle title={`Photos`} />
          <IconButton onClick={closeModal}>
            <Controls.Close />
          </IconButton>
        </Grid>
        <Grid container direction={'row'} justifyContent={'space-between'}>
          <Grid item style={{ marginRight: 8, marginLeft: 16 }}>
            <Typography style={{ color: '#3E3E3E', fontWeight: '700', fontSize: 20 }}>{'Before'}</Typography>
            <ImageLoad name={rent.startCheck.parameters.find((item: any) => item.orderIndex === choosen).photoName} />
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ color: '#3E3E3E', fontWeight: '700', fontSize: 20 }}>{'After'}</Typography>
            {rent.finishCheck === null ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Controls.NoPhoto />
                <Typography>{'No photo'}</Typography>
              </div>
            ) : (
              <ImageLoad
                name={rent.finishCheck.parameters.find((item: any) => item.orderIndex === choosen).photoName}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} justifyContent={'center'} direction={'row'} display={'flex'}>
          <Pagination
            count={rent.startCheck.parameters.filter((item: any) => item.answer === undefined).length}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Shared.Card>
  )
}
